#app { height: 100% }
html,
body {
  position: relative;
  height: 90%;
}

body {
  // background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}


.swiper {
  width: 85%;
  padding-top: 100px;
  padding-bottom: 120px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 250px;
  height: auto;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiperBorder2{
  width: 375px !important
}

.swiperBorder3{
  width: 1200px

}
.swiperBorder4{
  width: 280px !important;
}


// @media only screen and (max-width: 900px) {
//     .swiper {
//       width: 85%;
//       padding-top: 100px;
//       padding-bottom: 120px;
//     }
    
  
// }
